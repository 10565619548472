import React from "react";
import { urlApi } from "../../services/apirest";
import axios from "axios";
import { useNavigate } from 'react-router-dom';
import Header from "../Header";

var pagina = 1;
var total_paginas;
var cadena = "";

class DatosAlquiler extends React.Component {
    state = {
        alquiler: [],
        num_paginas: 0
    }
    clickEditar(id) {
        sessionStorage.setItem("IdRegistroSeleccionado", id);
        this.props.navigate('/editaralquiler');
    }
    NuevoRegistro() {
        this.props.navigate('/nuevoalquiler');
    }
    paginaSiguiente = () => {
        let num_p = this.state.num_paginas[0].numpag;
        console.log(num_p);
        if (num_p > pagina) {
            pagina = pagina + 1;
            let url = urlApi + "alquiler.php?page=" + pagina + "&cadena=" + cadena;
            console.log(url);
            axios
                .get(url)
                .then(response => {
                    this.setState({
                        alquiler: response.data[0]
                    });
                })
        }

    }
    paginaAnterior = () => {
        if (pagina !== 1) {
            pagina = pagina - 1;
            let url = urlApi + "alquiler.php?page=" + pagina + "&cadena=" + cadena;
            console.log(url);
            axios
                .get(url)
                .then(response => {
                    this.setState({
                        alquiler: response.data[0]
                    });
                })
        }
    }

    buscarTexto = async e => {
        if (e.charCode === 13) {
            pagina = 1;
            cadena = e.target.value;
            let url = urlApi + "alquiler.php?page=" + pagina + "&cadena=" + cadena;
            console.log(url);
            this.componentDidMount();
        }

    }

    delete = async (idalquiler) => {
        //if (await confirm('¿Desea eliminar la película ' + nombrepelicula + '?')) {
        let url = urlApi + "alquiler.php";
        console.log(idalquiler)
        let datos = {
            "token": sessionStorage.getItem("token"),
            "idalquiler": idalquiler,
            "metodo": "delete"
        }
        axios
            .post(url, datos)
            .then(response => {
                this.componentDidMount();
            })
        // } else {
        //     //setMessage('no');
        // }
    }

    componentDidMount = () => {

        let url = urlApi + "alquiler.php?page=" + pagina + "&cadena=" + cadena;
        axios
            .get(url)
            .then(response => {
                this.setState({
                    alquiler: response.data[0],
                    num_paginas: response.data[1]
                });
                total_paginas = response.data[1][0].numpag;
                //console.log(total_paginas);
                if (pagina > response.data[1][0].numpag) {
                    this.paginaAnterior();
                }
            })

    }
    render() {
        return (
            <div>
                <Header />
                <div className="container">
                    <button type="button" className="btn btn-primary" onClick={() => this.NuevoRegistro()} style={{ marginRight: "10px" }}>Nuevo Registro</button>
                    <input type="text" name="" className="" id="" onKeyPress={this.buscarTexto} />
                    <div className="container">
                        <table className="table">
                            <thead>
                                <tr>
                                    <th scope="col">ID</th>
                                    <th scope="col">Fecha Alquiler</th>
                                    <th scope="col">Fecha Devolución</th>
                                    <th scope="col">Cliente</th>

                                    <th scope="col">Acciones</th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.state.alquiler.map((value, index) => {
                                    return (
                                        <tr key={index} >
                                            <th scope="row">{value.idalquiler}</th>
                                            <td>{value.fecha_hora_alquiler}</td>
                                            <td>{value.fecha_hora_devolucion}</td>
                                            <td>{value.apellidos + " " + value.nombres}</td>

                                            <td>
                                                <svg onClick={() => this.clickEditar(value.idalquiler)} xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-edit" width="20" height="20" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#00abfb" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                                    <path d="M7 7h-1a2 2 0 0 0 -2 2v9a2 2 0 0 0 2 2h9a2 2 0 0 0 2 -2v-1" />
                                                    <path d="M20.385 6.585a2.1 2.1 0 0 0 -2.97 -2.97l-8.415 8.385v3h3l8.385 -8.415z" />
                                                    <path d="M16 5l3 3" />
                                                </svg>
                                                <svg onClick={() => this.delete(value.idalquiler)} xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-trash" width="20" height="20" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#ff2825" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                                    <path d="M4 7l16 0" />
                                                    <path d="M10 11l0 6" />
                                                    <path d="M14 11l0 6" />
                                                    <path d="M5 7l1 12a2 2 0 0 0 2 2h8a2 2 0 0 0 2 -2l1 -12" />
                                                    <path d="M9 7v-3a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v3" />
                                                </svg>

                                            </td>
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </table>
                        <nav>
                            <center>
                                <button type="button" onClick={this.paginaAnterior} style={{ marginRight: "10px" }} className="btn btn-success">{"< "}Anterior</button>
                                <input type="text" value={pagina + " de " + total_paginas} readOnly style={{ marginRight: "10px", width: "120px", textAlign: "center" }} ></input>
                                <button type="button" onClick={this.paginaSiguiente} style={{ marginRight: "10px" }} className="btn btn-success">Siguiente{" >"}</button>
                            </center>
                        </nav>
                    </div>
                </div>
            </div>
        )
    }
}

function WithNavigate(props) {
    let navigate = useNavigate();
    return <DatosAlquiler {...props} navigate={navigate} />
}

export default WithNavigate