import React from 'react';
import './css/App.css';
import './css/App.css';
import 'bootstrap/dist/css/bootstrap.css'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Login from './components/Login';

import DatosPeliculas from './components/peliculas/DatosPeliculas';
import NuevaPelicula from './components/peliculas/NuevaPelicula';
import EditarPelicula from './components/peliculas/EditarPelicula';

import DatosClientes from './components/clientes/DatosClientes';
import NuevoCliente from './components/clientes/NuevoCliente';
import EditarCliente from './components/clientes/EditarCliente';

import DatosAlquiler from './components/alquiler/DatosAlquiler';
import NuevoAlquiler from './components/alquiler/NuevoAlquiler';
import Header from './components/Header';
class App extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      NoModal: true,
      idForaneo: "0",
      datoForaneo: "0"
    };
    this.EditarVariable = this.EditarVariable.bind(this);
  }

  EditarVariable = (valorid, valorDato) => {
    this.setState({ 
      idForaneo: valorid,
      datoForaneo: valorDato
     });
  }

  validarSoloTexto = (texto, obligatorio = false) => {//solo acepta texto mayúscula, minúscula, eñes y tildes
    if (obligatorio == false && texto == "") {
      return true;
    } else {
      let ExpRegText = /^[A-ZÑa-zñáéíóúÁÉÍÓÚ'° ]+$/;
      if (ExpRegText.test(texto)) {
        return true;
      } else {
        return false;
      }
    }
  }

  notificacion = (mensaje) => {
    const parrafo = document.createElement('P');//CREA UN ELEMENTO P
    parrafo.textContent = mensaje;//agrega texto al parrafo
    parrafo.classList.add('alert');//Agrega una clase al párrafo
    parrafo.classList.add('alert-danger');//Agrega una clase al párrafo
    document.querySelector('.notificacion').appendChild(parrafo);
    setTimeout(() => {
      parrafo.remove();
    }, 3500);
  }

  render() {
    return (
      <React.Fragment>
        <p className='notificacion'></p>
        <Router>
          <Routes>
            <Route path='/' element={<Login />} />
            <Route path='/header' element={<Header />} />
            <Route path='/datospeliculas' element={<DatosPeliculas />} />
            <Route path='/nuevapelicula' element={<NuevaPelicula validarSoloTexto={this.validarSoloTexto} notificacion={this.notificacion} />} />
            <Route path='/editarpelicula' element={<EditarPelicula validarSoloTexto={this.validarSoloTexto} notificacion={this.notificacion} />} />

            <Route path='/datosclientes' element={<DatosClientes />} />
            <Route path='/nuevocliente' element={<NuevoCliente validarSoloTexto={this.validarSoloTexto} notificacion={this.notificacion} />} />
            <Route path='/editarcliente' element={<EditarCliente validarSoloTexto={this.validarSoloTexto} notificacion={this.notificacion} />} />

            <Route path='/datosalquiler' element={<DatosAlquiler />} />
            <Route path='/nuevoalquiler' element={<NuevoAlquiler EditarVariable={this.EditarVariable} idForaneo={this.state.idForaneo} datoForaneo={this.state.datoForaneo} ModoModal={this.state.ModoModal} />} />
          </Routes>
        </Router>
      </React.Fragment>
    );
  }
}

export default App;
