import React from "react";
import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";
function Header() {
    return (
        <div>
            <center>
                <Link to="/datosclientes">
                    <Button style={{ marginRight: "20px" }}>Clientes</Button>
                </Link>
                <Link to="/datospeliculas">
                    <Button style={{ marginRight: "20px" }}>Peliculas</Button>
                </Link>
                <Link to="/datosalquiler">
                    <Button style={{ marginRight: "20px" }}>Alquiler</Button>
                </Link>
            </center>
        </div>
    )
}

export default Header