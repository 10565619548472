import React from "react";
import '../css/Login.css';
import { urlApi } from "../services/apirest";
import axios from "axios";
import { useNavigate } from "react-router-dom";

class Login extends React.Component {
    state = {
        form: {
            "usuario": "usuario",
            "contrasena": "usuario"
        },
        error: false,
        errorMsg: ""
    }

    manejadorOnchange = async e => {
        this.setState({
            form: {
                ...this.state.form,
                [e.target.name]: e.target.value
            }
        })
        console.log(this.state.form);
    }

    manejadorLogin = () => {
        let url = urlApi + "auth.php";
        axios.post(url, this.state.form)
            .then(response => {
                if (response.data.status === "ok") {
                    localStorage.setItem("token", response.data.result.token);
                    //window.location.href="/datospeliculas";
                    this.props.navigate('/header');
                } else {
                    this.setState({
                        error: true,
                        errorMsg: response.data.result.error_msg
                    })
                }
            })
            .catch(error => {
                this.setState({
                    error: false,
                    errorMsg: "Error de conexión"
                })
            })
    }

    render() {
        return (
            <React.Fragment>
                <section className="vh-100">
                    <div className="container-fluid h-custom">
                        <div className="row d-flex justify-content-center align-items-center h-100">
                            <div className="col-md-9 col-lg-6 col-xl-5">
                                <img src="https://mdbcdn.b-cdn.net/img/Photos/new-templates/bootstrap-login-form/draw2.webp" className="img-fluid" alt="Login" />
                            </div>
                            <div className="col-md-8 col-lg-6 col-xl-4 offset-xl-1">
                                <form>
                                    <div className="d-flex flex-row align-items-center justify-content-center justify-content-lg-start">
                                        
                                    </div>

                                    <div className="form-outline mb-4">
                                        <input type="text" id="usuario" className="form-control form-control-lg" name="usuario" placeholder="Ingrese su usuario" onChange={this.manejadorOnchange} />
                                        <label className="form-label" htmlFor="usuario">Usuario</label>
                                    </div>


                                    <div className="form-outline mb-3">
                                        <input type="password" id="form3Example4" className="form-control form-control-lg" name="contrasena" placeholder="Enter password" onChange={this.manejadorOnchange} />
                                        <label className="form-label" htmlFor="form3Example4">Contraseña</label>
                                    </div>

                                    <div className="text-center text-lg-start mt-4 pt-2">
                                        <button type="button" className="btn btn-primary btn-lg" onClick={this.manejadorLogin}>Login</button>
                                    </div>

                                </form>
                                <br/>
                                {this.state.error === true &&
                                <div className="alert alert-danger" role="alert">
                                    {this.state.errorMsg}
                                </div>
                                }
                            </div>
                        </div>
                    </div>
                    <div
                        className="d-flex flex-column flex-md-row text-center text-md-start justify-content-between py-4 px-4 px-xl-5 bg-primary">

                        <div className="text-white mb-3 mb-md-0">
                            Copyright © 2020. All rights reserved.
                        </div>

                        <div>
                            <a href="#!" className="text-white me-4">
                                <i className="fab fa-facebook-f"></i>
                            </a>
                            <a href="#!" className="text-white me-4">
                                <i className="fab fa-twitter"></i>
                            </a>
                            <a href="#!" className="text-white me-4">
                                <i className="fab fa-google"></i>
                            </a>
                            <a href="#!" className="text-white">
                                <i className="fab fa-linkedin-in"></i>
                            </a>
                        </div>

                    </div>
                </section>
            </React.Fragment>
        );
    }

}

function Navegacion(props) {
    let navigate = useNavigate();
    return <Login {...props} navigate={navigate} />
}

export default Navegacion;