import React from "react";
//import Header from "../../templates/Header";
import { urlApi } from "../../services/apirest";
import axios from "axios";
import { useNavigate } from 'react-router-dom';
import { confirm } from "../Confirmation";
import Header from "../Header";

var pagina = 1;
var total_paginas;
var cadena = "";

class DatosClientes extends React.Component {

    state = {
        clientes: [],
        num_paginas: 0
    }


    clickCliente(id) {
        sessionStorage.setItem("IdRegistroSeleccionado", id);
        this.props.navigate('/editarcliente');
    }
    NuevoRegistro() {
        this.props.navigate('/nuevocliente');
    }

    IrComponente1() {

        const { EditarVariable } = this.props;
        EditarVariable(false);
        this.props.navigate('/componente1');
        setTimeout(() => {
            console.log(this.props.NuevoRegistro);
        }, 200);

    }
    paginaSiguiente = () => {
        let num_p = this.state.num_paginas[0].numpag;
        //console.log(num_p);
        if (num_p > pagina) {
            pagina = pagina + 1;
            let url = urlApi + "cliente.php?page=" + pagina + "&cadena=" + cadena;
            console.log(url);
            axios
                .get(url)
                .then(response => {
                    this.setState({
                        clientes: response.data[0]
                    });
                })
        }

    }
    paginaAnterior = () => {
        if (pagina !== 1) {
            pagina = pagina - 1;
            let url = urlApi + "cliente.php?page=" + pagina + "&cadena=" + cadena;
            console.log(url);
            axios
                .get(url)
                .then(response => {
                    this.setState({
                        clientes: response.data[0]
                    });
                })
        }
    }

    buscarTexto = async e => {
        if (e.charCode === 13) {
            pagina = 1;
            cadena = e.target.value;
            let url = urlApi + "cliente.php?page=" + pagina + "&cadena=" + cadena;
            console.log(url);
            this.componentDidMount();
        }

    }

    delete = async (idcliente, cedulacliente) => {
        // if (await confirm("Are your sure?")) {
        //     console.log("Si");
        //   } else {
        //     console.log("No");
        //   }
        if (await confirm('¿Desea eliminar al cliente con cédula ' + cedulacliente + '?')) {
            let url = urlApi + "cliente.php";
            //var idcliente = sessionStorage.getItem("IdRegistroSeleccionado");
            let datos = {
                "token": sessionStorage.getItem("token"),
                "idcliente": idcliente,
                "metodo": "delete"
            }
            axios
                .post(url, datos)
                .then(response => {
                    this.componentDidMount();
                    //this.props.navigate('/datosclientes');
                })
        } else {
            //setMessage('no');
        }
    }

    componentDidMount = () => {
        let url = urlApi + "cliente.php?page=" + pagina + "&cadena=" + cadena;
        axios
            .get(url)
            .then(response => {
                this.setState({
                    clientes: response.data[0],
                    num_paginas: response.data[1]
                });
                total_paginas = response.data[1][0].numpag;
                //console.log(total_paginas);
                if (pagina > response.data[1][0].numpag) {
                    this.paginaAnterior();
                }
            })

        setTimeout(() => {
            console.log(this.props.NoModal);
        }, 1000);
    }

    // cambiarIdForaneo(codigo, dato) {
    //     const { EditarVariable } = this.props;
    //     EditarVariable(codigo, dato);
    //     const { cerrarModal } = this.props;
    //     cerrarModal();
    //     const { manejadorOnchange } = this.props;
    //     manejadorOnchange({ target: { name: 'idcliente', value: codigo } });

    // }
    render() {
        return (
            <div>

                <Header />

                <div className="container">
                    <h2>Datos de Clientes</h2>
                    <button type="button" className="btn btn-success" onClick={() => this.NuevoRegistro()} style={{ marginRight: "10px" }}>Nuevo Cliente</button>
                    <input type="text" name="" className="" id="" onKeyPress={this.buscarTexto} />
                    <a href={urlApi + 'reportes/reporte.php?id=' + 1} target="_blank">enlace</a>
                    <div className="container">
                        <table className="table table-hover">
                            <thead>
                                <tr>
                                    <th scope="col">ID</th>
                                    <th scope="col">Cédula</th>
                                    <th scope="col">Nombres</th>
                                    <th scope="col">Apellidos</th>
                                    <th scope="col">Teléfono</th>
                                    <th scope="col">Acciones</th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.state.clientes.map((value, index) => {
                                    return (
                                        <tr key={index} >
                                            <td>{value.idcliente}</td>
                                            <td>{value.cedula}</td>
                                            <td>{value.nombres}</td>
                                            <td>{value.apellidos}</td>
                                            <td>{value.telefono}</td>
                                            <td>
                                                <div>
                                                    <svg onClick={() => this.clickCliente(value.idcliente)} xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-edit" width="20" height="20" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#00abfb" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                                        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                                        <path d="M7 7h-1a2 2 0 0 0 -2 2v9a2 2 0 0 0 2 2h9a2 2 0 0 0 2 -2v-1" />
                                                        <path d="M20.385 6.585a2.1 2.1 0 0 0 -2.97 -2.97l-8.415 8.385v3h3l8.385 -8.415z" />
                                                        <path d="M16 5l3 3" />
                                                    </svg>
                                                    <svg onClick={() => this.delete(value.idcliente, value.cedula)} xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-trash" width="20" height="20" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#ff2825" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                                        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                                        <path d="M4 7l16 0" />
                                                        <path d="M10 11l0 6" />
                                                        <path d="M14 11l0 6" />
                                                        <path d="M5 7l1 12a2 2 0 0 0 2 2h8a2 2 0 0 0 2 -2l1 -12" />
                                                        <path d="M9 7v-3a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v3" />
                                                    </svg>
                                                </div>

                                            </td>
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </table>
                        <nav>
                            <center>
                                <button type="button" onClick={this.paginaAnterior} style={{ marginRight: "10px" }} className="btn btn-success">{"< "}Anterior</button>
                                <input type="text" value={pagina + " de " + total_paginas} readOnly style={{ marginRight: "10px", width: "120px", textAlign: "center" }} ></input>
                                <button type="button" onClick={this.paginaSiguiente} style={{ marginRight: "10px" }} className="btn btn-success">Siguiente{" >"}</button>
                            </center>
                        </nav>
                    </div>
                </div>
            </div>
        )
    }
}

function WithNavigate(props) {
    let navigate = useNavigate();
    return <DatosClientes {...props} navigate={navigate} />
}

export default WithNavigate