import React from "react";
import { useNavigate } from 'react-router-dom';
import axios from "axios";
import { urlApi } from "../../services/apirest";
import 'bootstrap/dist/css/bootstrap.css';
import { confirm } from '../Confirmation';
import Header from "../Header";

var pagina = 1;
var total_paginas;
var cadena = ""

class DatosPeliculas extends React.Component {
    //codificar funciones, states, entre otros

    state = {
        registros: [],
        num_paginas: 0
    }

    paginaSiguiente = () => {
        let num_p = this.state.num_paginas[0].num_pag;
        console.log(num_p);
        if (num_p > pagina) {
            pagina = pagina + 1;
            let url = urlApi + "peliculas.php?page=" + pagina + "&cadena=" + cadena;
            axios
                .get(url)
                .then(response => {
                    this.setState({
                        registros: response.data[0]
                    })
                })
                .catch(error => {
                    console.log("Error de conexión");
                })
        }
    }

    paginaAnterior = () => {
        if (pagina !== 1) {
            pagina = pagina - 1;
            let url = urlApi + "peliculas.php?page=" + pagina + "&cadena=" + cadena;
            axios
                .get(url)
                .then(response => {
                    this.setState({
                        registros: response.data[0]
                    })
                })
                .catch(error => {
                    console.log("Error de conexión");
                })
        }
    }

    buscarTexto = async e => {
        if (e.charCode === 13) {
            pagina = 1;
            cadena = e.target.value;
            this.componentDidMount();
        }
    }

    ir_a_Nuevo = () => {
        this.props.navigate('/nuevapelicula');
    }

    componentDidMount = () => {
        let url = urlApi + "peliculas.php?page=" + pagina + "&cadena=" + cadena;
        axios
            .get(url)
            .then(response => {
                this.setState({
                    registros: response.data[0],
                    num_paginas: response.data[1]
                })
                total_paginas = response.data[1][0].num_pag;
            })
            .catch(error => {
                console.log("Error de conexión");
            })
    }

    ir_a_Editar(id) {
        localStorage.setItem("codigo_registro", id);
        this.props.navigate('/editarpelicula');
    }

    eliminar_registro = async (id, nombre) => {
        if (await confirm('¿Desea eliminar la pelicula ' + nombre + '?')) {
            let url = urlApi + "peliculas.php";
            let datos = {
                "idpeliculas": id,
                "token": localStorage.getItem('token'),
                "metodo": "delete"
            }
            axios
                .post(url, datos)
                .then(response => {
                    this.componentDidMount();
                })
                .catch(error => {
                    console.log("Error el eliminar");
                })
        } else {

        }

    }


    render() {
        return (
            <div>
                <Header />
                <div className="container">
                    <h2>Datos de Películas</h2>
                    <button className="btn btn-success" onClick={this.ir_a_Nuevo} style={{ marginRight: "10px" }}>Nueva Película</button>
                    <input type="text" placeholder="Buscar por nombre y fecha estreno" onKeyPress={this.buscarTexto} />
                    <table className="table">
                        <thead>
                            <tr>
                                <th scope="col">ID</th>
                                <th scope="col">Nombre</th>
                                <th scope="col">Fecha Estreno</th>
                                <th scope="col">Duración</th>
                                <th scope="col">Genero</th>
                                <th scope="col">Acciones</th>
                            </tr>
                        </thead>
                        <tbody>
                            {this.state.registros.map((value, index) => {
                                return (
                                    <tr key={index}>
                                        <th scope="row">{value.idpeliculas}</th>
                                        <td>{value.nombre_pelicula}</td>
                                        <td>{value.fecha_estreno}</td>
                                        <td>{value.duracion}</td>
                                        <td>{value.genero}</td>
                                        <td>
                                            <svg onClick={() => this.ir_a_Editar(value.idpeliculas)} xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-edit" width="20" height="20" viewBox="0 0 24 24" stroke-width="1.5" stroke="#00abfb" fill="none" stroke-linecap="round" stroke-linejoin="round">
                                                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                                <path d="M7 7h-1a2 2 0 0 0 -2 2v9a2 2 0 0 0 2 2h9a2 2 0 0 0 2 -2v-1" />
                                                <path d="M20.385 6.585a2.1 2.1 0 0 0 -2.97 -2.97l-8.415 8.385v3h3l8.385 -8.415z" />
                                                <path d="M16 5l3 3" />
                                            </svg>
                                            <svg onClick={() => this.eliminar_registro(value.idpeliculas, value.nombre_pelicula)} xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-trash" width="20" height="20" viewBox="0 0 24 24" stroke-width="1.5" stroke="#ff2825" fill="none" stroke-linecap="round" stroke-linejoin="round">
                                                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                                <path d="M4 7l16 0" />
                                                <path d="M10 11l0 6" />
                                                <path d="M14 11l0 6" />
                                                <path d="M5 7l1 12a2 2 0 0 0 2 2h8a2 2 0 0 0 2 -2l1 -12" />
                                                <path d="M9 7v-3a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v3" />
                                            </svg>
                                            
                                        </td>
                                    </tr>
                                )
                            })}


                        </tbody>
                    </table>
                    <nav>
                        <center>
                            <button type="button" onClick={this.paginaAnterior} style={{ marginRight: "10px" }} className="btn btn-success" >Página Anterior</button>
                            <input type="text" value={pagina + " de " + total_paginas} readOnly style={{ marginRight: "10px", width: "120px", textAlign: "center" }} />
                            <button type="button" onClick={this.paginaSiguiente} style={{ marginRight: "10px" }} className="btn btn-success" >Página Siguiente</button>
                        </center>
                    </nav>
                </div>

            </div>
        )
    }

}

function Navegacion(props) {
    let navigate = useNavigate();
    return <DatosPeliculas {...props} navigate={navigate} />
}

export default Navegacion