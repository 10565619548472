import React from "react";
import { urlApi } from "../../services/apirest";
import axios from "axios";
import Header from "../Header";
import { useNavigate } from 'react-router-dom';

class EditarCliente extends React.Component {
    state = {
        form: {
            "cedula": "",
            "nombres": "",
            "apellidos": "",
            "telefono": "",
            "correo": "",
            "direccion": "",            
            "fecha_nacimiento": "",
            "token": "",
            "idcliente": "",
            "metodo": "put"
        },
        error: false,
        errorMsg: ""
    }

    manejadorOnchange = async e => {
        this.setState({
            form: {
                ...this.state.form,
                [e.target.name]: e.target.value
            }
        })
        console.log(this.state.form);
    }

    put = () => {
        let url = urlApi + "cliente.php";
        axios
            .post(url, this.state.form)
            //.put(url, this.state.form)
            .then(response => {
                console.log(response);
                this.props.navigate('/datosclientes');
            })
            .catch(error => {
                console.log(error);
            })
    }

    salir = () => {
        this.props.navigate('/datosclientes');
    }

    async componentDidMount() {
        let idcliente = sessionStorage.getItem("IdRegistroSeleccionado");
        if (idcliente === 0) {
            window.location.href = "../datosclientes";
        } else {
            let url_getid = urlApi + "cliente.php?id=" + idcliente;
            //console.log(url_getid);
            await axios
                .get(url_getid)
                .then(response => {
                    this.setState({
                        form: {
                            cedula: response.data[0].cedula,
                            nombres: response.data[0].nombres,
                            apellidos: response.data[0].apellidos,
                            telefono: response.data[0].telefono,
                            correo: response.data[0].correo,
                            direccion: response.data[0].direccion,
                            fecha_nacimiento: response.data[0].fecha_nacimiento,
                            token: localStorage.getItem("token"),
                            idcliente: response.data[0].idcliente,
                            metodo: "put"
                        }
                    })
                })
                .catch((error) => {
                    console.log(error);
                })
        }
        //console.log(this.state.form.nombre + " 111");
    }


    render() {
        const formData = this.state.form;
        return (
            <React.Fragment>
                <Header />
                <div className="position-absolute top-1 start-50 translate-middle-x">
                    <div className="container">
                        <h2>Editar Cliente</h2>
                    </div>
                    <div className="container">
                        <form className="form-horizontal">
                            <div className="row"> 
                                <div className="col-12 col-sm-6">
                                    <div className="mb-1">
                                        <label htmlFor="nombres" className="form-label">Nombres</label>
                                        <input className="form-control" name="nombres" placeholder="Nombres del cliente" type="text" defaultValue={formData.nombres} onChange={this.manejadorOnchange} />
                                    </div>
                                </div>
                                <div className="col-12 col-sm-6">
                                    <div className="mb-1">
                                        <label htmlFor="apellidos" className="form-label">Apellidos</label>
                                        <input className="form-control" name="apellidos" placeholder="Apellidos" type="text" defaultValue={formData.apellidos} onChange={this.manejadorOnchange} />
                                    </div>
                                </div>
                                <div className="w-100"></div>
                                <div className="col-12 col-sm-6">
                                    <div className="mb-1">
                                        <label htmlFor="cedula" className="form-label">Cédula</label>
                                        <input className="form-control" name="cedula" placeholder="Cédula" type="text" defaultValue={formData.cedula} onChange={this.manejadorOnchange} />
                                    </div>
                                </div>
                                <div className="col-12 col-sm-6">
                                    <div className="mb-1">
                                        <label htmlFor="telefono" className="form-label">Teléfono</label>
                                        <input className="form-control" name="telefono" placeholder="Teléfono" type="text" defaultValue={formData.telefono} onChange={this.manejadorOnchange} />
                                    </div>
                                </div>
                                <div className="w-100"></div>
                                <div className="col-12 col-sm-6">
                                    <div className="mb-1">
                                        <label htmlFor="correo" className="form-label">Correo</label>
                                        <input className="form-control" name="correo" placeholder="Correo" type="email" defaultValue={formData.correo} onChange={this.manejadorOnchange} />
                                    </div>
                                </div>
                                <div className="col-12 col-sm-6">
                                    <div className="mb-1">
                                        <label htmlFor="fecha_nacimiento" className="form-label">Fecha Nacimiento</label>
                                        <input className="form-control" name="fecha_nacimiento" placeholder="Correo" type="date" defaultValue={formData.fecha_nacimiento} onChange={this.manejadorOnchange} />
                                    </div>
                                </div>
                                <div className="w-100"></div>
                                <div className="col-24 col-sm-12">
                                    <div className="mb-1">
                                        <label htmlFor="direccion" className="form-label">Dirección</label>
                                        <input className="form-control" name="direccion" placeholder="Dirección" type="text" defaultValue={formData.direccion} onChange={this.manejadorOnchange} />
                                    </div>
                                </div>
                            </div>
                            <button type="button" className="btn btn-primary" onClick={() => this.put()} style={{ marginRight: "10px" }}>Editar</button>
                            
                            <button type="button" className="btn btn-dark" onClick={() => this.salir()}>Salir</button>
                        </form>
                        {this.state.error === true &&
                            <div className="alert alert-danger" role="alert">
                                {this.state.errorMsg}
                            </div>
                        }
                    </div>
                </div>
            </React.Fragment>
        );
    }

}

function WithNavigate(props) {
    let navigate = useNavigate();
    return <EditarCliente {...props} navigate={navigate} />
}

export default WithNavigate