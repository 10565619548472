import React from "react";
import { useNavigate } from "react-router-dom";
import { urlApi } from "../../services/apirest";
import axios from "axios";

class NuevaPelicula extends React.Component {

    state = {
        form: {
            "nombre_pelicula": "",
            "fecha_estreno": "",
            "duracion": "",
            "genero": "",
            "notas": "",
            "token": localStorage.getItem('token'),
            "metodo": "post"
        }
    }

    manejadorOnChange = async e => {
        this.setState({
            form: {
                ...this.state.form,
                [e.target.name]: e.target.value
            }
        })
        console.log(this.state.form)
    }

    guardar = () => {
        const { notificacion } = this.props;
        const { validarSoloTexto } = this.props;
        if (validarSoloTexto(this.state.form.genero, false) == true) {
            notificacion('El género no es correcto');
        } else {
            let url = urlApi + "peliculas.php";
            axios
                .post(url, this.state.form)
                .then(response => {
                    this.props.navigate("/datospeliculas");
                })
                .catch(error => {
                    console.log("Error al agregar");
                })
        }

    }

    render() {
        return (
            <div>

                <div>
                    <div className="position-absolute top-0 end-0 notificacion">

                    </div>
                    <div className="col-4 position-absolute top-0 start-50 translate-middle-x">
                        <h2>Nuevo registro de película</h2>
                        <form>
                            <div className="mb-3">
                                <label htmlFor="nombre_pelicula" className="form-label">Nombre Pelicula</label>
                                <input type="text" name="nombre_pelicula" onChange={this.manejadorOnChange} className="form-control nombre" />
                            </div>
                            <div className="mb-3">
                                <label htmlFor="fecha_estreno" className="form-label">Fecha de Estreno</label>
                                <input type="date" name="fecha_estreno" onChange={this.manejadorOnChange} className="form-control nombre" />
                            </div>
                            <div className="mb-3">
                                <label htmlFor="duracion" className="form-label">Duración</label>
                                <input type="text" name="duracion" onChange={this.manejadorOnChange} className="form-control cedula" />
                            </div>
                            <div className="mb-3">
                                <label htmlFor="genero" className="form-label">Género</label>
                                <input type="text" name="genero" onChange={this.manejadorOnChange} className="form-control email" />
                            </div>
                            <div className="mb-3">
                                <label htmlFor="notas" className="form-label">Notas</label>
                                <input type="text" name="notas" onChange={this.manejadorOnChange} className="form-control" id="edad" />
                            </div>

                            <button type="button" onClick={this.guardar} className="btn btn-primary">Enviar</button>
                        </form>
                    </div>
                </div>
            </div>
        )
    }
}

function Navegacion(props) {
    let navigate = useNavigate();
    return <NuevaPelicula {...props} navigate={navigate} />
}

export default Navegacion