import React from "react";
import { urlApi } from "../../services/apirest";
import axios from "axios";
import { useNavigate } from 'react-router-dom';
import Header from "../Header";
import DatosClientes from '../clientes/DatosClientes';

class NuevoAlquiler extends React.Component {

    abrirModal = () => {
        this.setState({ mostrarModal: true });
    }

    cerrarModal = () => {
        this.setState({ mostrarModal: false });
    }

    state = {
        form: {
            "fecha_hora_alquiler": "",
            "fecha_hora_devolucion": "",
            "observacion": "",
            "idcliente": "",
            "idusuario": localStorage.getItem("idUsuario"),
            "token": localStorage.getItem("token"),
            "metodo": "post"
        },
        mostrarModal: false
    }

    manejadorOnchange = async e => {
        this.setState({
            form: {
                ...this.state.form,
                [e.target.name]: e.target.value
            }
        })
        console.log(this.state.form);
    }

    post = () => {
        let url = urlApi + "alquiler.php";
        axios
            .post(url, this.state.form)
            .then(response => {
                this.props.navigate('/datosalquiler');
            })
            .catch(error => {
                console.log(error);
            })
    }

    salir = () => {
        this.props.navigate('/datosalquiler');
    }

    componentDidMount() {
        const { EditarVariable } = this.props;
        EditarVariable("", "");
    }

    render() {
        return (
            <React.Fragment>
                <Header />
                <div className="position-absolute top-1 start-50 translate-middle-x">
                    <div className="container">
                        <h2>Nueva Alquiler</h2>
                    </div>
                    <div className="container">
                        <form className="form-horizontal">
                            <div className="row">
                                <div className="col-12 col-sm-6">
                                    <div className="mb-1">
                                        <label htmlFor="fecha_hora_alquiler" className="form-label">Fecha del alquiler</label>
                                        <input className="form-control" name="fecha_hora_alquiler" type="datetime-local" onChange={this.manejadorOnchange} />
                                    </div>
                                </div>
                                <div className="col-12 col-sm-6">
                                    <div className="mb-1">
                                        <label htmlFor="fecha_hora_devolucion" className="form-label">Fecha Devolución</label>
                                        <input className="form-control" name="fecha_hora_devolucion" type="datetime-local" onChange={this.manejadorOnchange} />
                                    </div>
                                </div>
                                <div className="w-100"></div>
                                <div className="col-24 col-sm-12">
                                    <div className="mb-1">
                                        <label htmlFor="observacion" className="form-label">Observación</label>
                                        <input className="form-control" name="observacion" placeholder="Observación" type="text" onChange={this.manejadorOnchange} />
                                    </div>
                                </div>
                                <div className="w-100"></div>
                                <div className="col-24 col-sm-12">
                                    <div className="mb-1">
                                        <label htmlFor="cliente" className="form-label">Cliente</label>
                                        <input className="form-control" name="cliente" placeholder="Cliente" type="text" value={this.props.datoForaneo} onClick={this.abrirModal} />
                                    </div>
                                </div>
                                <div className="col-24 col-sm-12">
                                    <div className="mb-1">
                                        <input className="form-control" name="idcliente" placeholder="IdCliente" type="text" value={this.props.idForaneo} />
                                    </div>
                                </div>
                            </div>
                            <button type="button" className="btn btn-primary" onClick={() => this.post()} style={{ marginRight: "10px" }}>Guardar</button>
                            <button type="button" className="btn btn-dark" onClick={() => this.salir()}>Salir</button>
                        </form>
                    </div>
                </div>
                {this.state.mostrarModal &&
                    <div className="modal">
                        <div className="modal-content">
                            <span className="close" onClick={this.cerrarModal}>&times;</span>
                            <DatosClientes EditarVariable={this.props.EditarVariable} cerrarModal={this.cerrarModal} manejadorOnchange={this.manejadorOnchange} ModoModal={this.state.ModoModal}/>
                        </div>
                    </div>
                }
            </React.Fragment>
        );
    }

}

function WithNavigate(props) {
    //const [showModal, setShowModal] = useState(false);
    let navigate = useNavigate();
    return <NuevoAlquiler {...props} navigate={navigate}  />
}

export default WithNavigate