import React from "react";
import { urlApi } from "../../services/apirest";
import axios from "axios";
import Header from "../Header";
import { useNavigate } from 'react-router-dom';

class NuevoCliente extends React.Component {
    
    state = {
        form: {
            "cedula": "",
            "nombres": "",
            "apellidos": "",
            "telefono": "",
            "correo": "",
            "direccion": "",
            "fecha_nacimiento": "",
            "metodo": "post",
            "token": localStorage.getItem("token")
        },
        error: false,
        errorMsg: ""
    }



    manejadorOnchange = async e => {
        this.setState({
            form: {
                ...this.state.form,
                [e.target.name]: e.target.value
            }
        })
        console.log(this.state.form);
    }

    post = () => {
        const { notificacion } = this.props;
        const { validarCedula } = this.props;
        const { validarSoloTexto } = this.props;
        const { validarCorreo } = this.props;
        //const { validarFecha } = this.props;
        if (validarSoloTexto(this.state.form.nombres, true) === false) {
            notificacion("Nombre no válido");
        } else if (validarSoloTexto(this.state.form.apellidos, true) === false) {
            notificacion("Apellido no válido");
        // } else if (validarCedula(this.state.form.cedula, true) === false) {
        //     notificacion("Cédula no válida");
        // } else if (validarCorreo(this.state.form.correo, false) === false) {
        //     notificacion("Correo no válido");
        //} else if (validarFecha(this.state.form.fecha_nacimiento, true) === false) {
        //    notificacion("Fecha no válida");
        } else {
            let url = urlApi + "cliente.php";
            axios
                .post(url, this.state.form)
                .then(response => {
                    //console.log(response);
                    this.props.navigate('/datosclientes');
                })
                .catch(error => {
                    notificacion(error.response.data.result.error_msg);
                })
        }
    }

    salir = () => {
        this.props.navigate('/datosclientes');
    }

    render() {
        //const formData = this.state.form;
        return (
            <React.Fragment>
                <Header />
                
                {/* <div style={{position: "-webkit-sticky", position: "sticky", top: "0"}}>Mensaje</div> */}
                <div className="position-absolute top-1 start-50 translate-middle-x">
                    <div className="container">
                        <h2>Nuevo Cliente</h2>
                    </div>
                    <div className="container">
                        <form className="form-horizontal">
                            <div className="row">

                                <div className="col-12 col-sm-6">
                                    <div className="mb-1">
                                        <label htmlFor="nombres" className="form-label">Nombres</label>
                                        <input className="form-control" name="nombres" required placeholder="Nombres del cliente" type="text" onChange={this.manejadorOnchange} />
                                    </div>
                                </div>
                                <div className="col-12 col-sm-6">
                                    <div className="mb-1">
                                        <label htmlFor="apellidos" className="form-label">Apellidos</label>
                                        <input className="form-control" name="apellidos" placeholder="Apellidos" type="text" onChange={this.manejadorOnchange} />
                                    </div>
                                </div>
                                <div className="w-100"></div>
                                <div className="col-12 col-sm-6">
                                    <div className="mb-1">
                                        <label htmlFor="cedula" className="form-label">Cédula</label>
                                        <input className="form-control" name="cedula" id="cedula" placeholder="Cédula" type="text" onChange={this.manejadorOnchange} />
                                    </div>
                                </div>
                                <div className="col-12 col-sm-6">
                                    <div className="mb-1">
                                        <label htmlFor="telefono" className="form-label">Teléfono</label>
                                        <input className="form-control" name="telefono" placeholder="Teléfono" type="text" onChange={this.manejadorOnchange} />
                                    </div>
                                </div>
                                <div className="w-100"></div>
                                <div className="col-12 col-sm-6">
                                    <div className="mb-1">
                                        <label htmlFor="correo" className="form-label">Correo</label>
                                        <input className="form-control" name="correo" placeholder="Correo" type="email" onChange={this.manejadorOnchange} />
                                    </div>
                                </div>
                                <div className="col-12 col-sm-6">
                                    <div className="mb-1">
                                        <label htmlFor="fecha_nacimiento" className="form-label">Fecha Nacimiento</label>
                                        <input className="form-control" name="fecha_nacimiento" placeholder="Correo" type="date"  onChange={this.manejadorOnchange} />
                                    </div>
                                </div>
                                <div className="w-100"></div>
                                <div className="col-24 col-sm-12">
                                    <div className="mb-1">
                                        <label htmlFor="direccion" className="form-label">Dirección</label>
                                        <input className="form-control" name="direccion" placeholder="Dirección" type="text" onChange={this.manejadorOnchange} />
                                    </div>
                                </div>
                            </div>
                            <button type="button" id="btnEnviar" className="btn btn-primary" onClick={() => this.post()} style={{ marginRight: "10px" }}>Guardar</button>
                            <button type="button" className="btn btn-dark" onClick={() => this.salir()}>Salir</button>
                        </form>
                    </div>
                </div>
                <script src="../../templates/validaciones.js"></script>
            </React.Fragment>
        );
    }

}

function WithNavigate(props) {
    let navigate = useNavigate();
    return <NuevoCliente {...props} navigate={navigate} />
}

export default WithNavigate